<template>
  <div class="flex flex-col gap-16">
    <QuestionHeading :subtitle :title />
    <div class="flex justify-between gap-8" data-test="smiley-rate-input">
      <RevTooltip
        v-for="(smiley, index) of smileys"
        :key="index"
        class="block grow"
      >
        <template #trigger="{ hide, show }">
          <button
            class="flex w-full grow items-center justify-center rounded-[4px] p-8"
            :class="getIconContainerClass(index + 1)"
            :data-active="currentRate === index + 1"
            :data-test="`smiley-${index + 1}`"
            type="button"
            @click="() => updateRate(index + 1)"
            @focusin="show"
            @focusout="hide"
            @mouseenter="show"
            @mouseleave="hide"
          >
            <!-- eslint-disable tailwindcss/no-custom-classname -->
            <component
              :is="smiley"
              class="fill-bg-action-default-hi stroke-bg-action-default-hi !size-32"
              :style="style.icon"
            />
          </button>
        </template>

        {{ getTooltipText(index + 1) }}
      </RevTooltip>
    </div>

    <div v-if="!!errorMessage" class="text-static-danger-hi mt-8">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { tw } from '@backmarket/utils/string/tw'
import { RevTooltip } from '@ds/components/Tooltip'

import QuestionHeading from '../QuestionHeading.vue'

import translations from './SmileyInputRate.translations'
import SmileyRate1 from './icons/SmileyRate1.svg'
import SmileyRate2 from './icons/SmileyRate2.svg'
import SmileyRate3 from './icons/SmileyRate3.svg'
import SmileyRate4 from './icons/SmileyRate4.svg'
import SmileyRate5 from './icons/SmileyRate5.svg'

const i18n = useI18n()

defineProps<{ title: string; subtitle: string; errorMessage?: string }>()

const currentRate = defineModel<number>()

const updateRate = (newRate: number) => {
  currentRate.value = newRate
}

const smileys = [
  SmileyRate1,
  SmileyRate2,
  SmileyRate3,
  SmileyRate4,
  SmileyRate5,
]

const getIconContainerClass = (smileyRating: number) => {
  const hoverBackgroundClass = {
    1: tw`hover:bg-static-danger-hi`,
    2: tw`hover:bg-static-danger-hi`,
    3: tw`hover:bg-static-warning-hi`,
    4: tw`hover:bg-static-success-mid`,
    5: tw`hover:bg-static-success-hi`,
  }[smileyRating]

  const activeBackgroundClass = {
    1: tw`bg-static-danger-hi`,
    2: tw`bg-static-danger-hi`,
    3: tw`bg-static-warning-hi`,
    4: tw`bg-static-success-mid`,
    5: tw`bg-static-success-hi`,
  }[smileyRating]

  const emojiRatingIsSelected = currentRate.value === smileyRating

  const backgroundClass = emojiRatingIsSelected
    ? activeBackgroundClass
    : tw`bg-surface-default-mid`

  return [hoverBackgroundClass, backgroundClass]
}
const getTooltipText = (smileyRating: number) => {
  const translation = {
    1: translations.naught,
    2: translations.bad,
    3: translations.neutral,
    4: translations.good,
    5: translations.excellent,
  }[smileyRating]

  return translation ? i18n(translation) : ''
}
const style = useCssModule()
</script>

<style module>
.icon {
  transition: color 0.3s ease;
}
</style>
