<template>
  <div class="flex flex-col gap-32 lg:flex-row">
    <div
      class="bg-surface-brand-mid mood-cornflower rounded-md flex h-[196px] w-full shrink-0 items-center justify-center lg:w-[232px]"
    >
      <img alt="" class="h-[196px] w-auto" src="/img/reviews/help-center.svg" />
    </div>
    <div class="flex flex-col gap-8">
      <h2 class="text-static-default-hi heading-2">
        {{ title }}
      </h2>
      <div class="text-static-default-low body-1">
        {{ text }}
      </div>
    </div>
  </div>
  <RevButton
    :to="diagnosisRoute"
    :tracking="{
      name: 'get_help',
      zone: route.name as string,
    }"
    variant="primary"
    >{{ i18n(translations.contactMerchant) }}</RevButton
  >
</template>

<script lang="ts" setup>
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import type { Location } from '@ds/types/Location'

import { CUSTOMER_CARE } from '~/scopes/customer-care/route-names'

import { useReviewCollectionSource } from '../../composables/useReviewCollectionSource'

import translations from './ReviewSummary.translations'

const i18n = useI18n()
const route = useRoute()
const { isSolvedClaim } = useReviewCollectionSource()

const diagnosisRoute: Location = {
  name: CUSTOMER_CARE.DIAGNOSIS.SELF,
  params: {
    orderlineId: route.params.orderlineId,
  },
}

const title = computed(() =>
  isSolvedClaim.value
    ? i18n(translations.titleBadSolvedClaim)
    : i18n(translations.titleBad),
)
const text = computed(() =>
  isSolvedClaim.value
    ? i18n(translations.bodyBadSolvedClaim)
    : i18n(translations.bodyBad),
)
</script>
