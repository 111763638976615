<template>
  <RevContainer class="my-24 md:my-32">
    <ErrorBlock v-if="displayedError" v-bind="displayedError" />

    <div v-if="!showSummary" class="flex flex-col gap-32 md:flex-row">
      <ProductInfosCard
        v-if="product"
        :average-rate
        :product-image="product.imageUrl"
        :product-title="product.title"
      />

      <RevForm
        v-if="form"
        :initial-values="form"
        :validate="validators"
        @submit="submitStep"
      >
        <template #default="{ values, hasError, errors }">
          <RevCard class="p-32 md:w-640">
            <div class="flex flex-col gap-32">
              <GoBackButton
                v-if="!isFirstStep"
                @click="() => goToPreviousStep(values)"
              />

              <TopText :step-id="formStepId" />

              <div v-for="question of questions" :key="question.identifier">
                <CommentInput
                  v-if="isTextAreaQuestion(question)"
                  :id="question.identifier"
                  v-model="values[question.identifier] as string"
                  :error-message="errors[question.identifier]"
                  :subtitle="question.subtitle"
                  :title="question.title"
                />

                <SmileyInputRate
                  v-if="isRatingQuestion(question)"
                  v-model="values[question.identifier] as number"
                  :data-test="`smiley-rate-input-${question.identifier}`"
                  :error-message="errors[question.identifier]"
                  :subtitle="question.subtitle"
                  :title="question.title"
                  @update:model-value="
                    (rate) =>
                      onRate({
                        rate,
                        withCareModal: isQuestionWithCareModal(question),
                      })
                  "
                />

                <CareModal v-if="isQuestionWithCareModal(question)" />
              </div>

              <RevButton
                data-test="submit-button"
                :disabled="hasError || submissionPending"
                type="submit"
                variant="primary"
              >
                {{ submitButtonLabel }}
              </RevButton>

              <BottomText :step-id="formStepId" />
            </div>
          </RevCard>
        </template>
      </RevForm>
    </div>

    <ReviewSummary
      v-if="showSummary && product"
      :average-rate
      :is-positive-review
      :product-image="product.imageUrl"
      :product-title="product.title"
      @edit-review-clicked="editReview"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevForm } from '@ds/components/Form'

import BottomText from '../components/BottomText.vue'
import CareModal from '../components/CareModal/CareModal.vue'
import CommentInput from '../components/CommentInput/CommentInput.vue'
import ErrorBlock from '../components/ErrorBlock/ErrorBlock.vue'
import GoBackButton from '../components/GoBackButton/GoBackButton.vue'
import ProductInfosCard from '../components/ProductInfosCard/ProductInfosCard.vue'
import ReviewSummary from '../components/ReviewSummary/ReviewSummary.vue'
import SmileyInputRate from '../components/SmileyInputRate/SmileyInputRate.vue'
import TopText from '../components/TopText.vue'
import { useReviewCollection } from '../composables/useReviewCollection'
import type { ReviewForm, ReviewFormQuestion } from '../models/review-form'

const scrollToTheTop = () =>
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })

const {
  displayedError,
  submissionPending,
  form,
  validators,
  questions,
  submitStep: _submitStep,
  goToPreviousStep,
  editReview,
  isFirstStep,
  product,
  averageRate,
  isPositiveReview,
  showSummary,
  formStepId,
  onRate,
  submitButtonLabel,
} = await useReviewCollection()

async function submitStep(values: ReviewForm) {
  await _submitStep(values)
  scrollToTheTop()
}

const isTextAreaQuestion = (question: ReviewFormQuestion) =>
  question.type === 'textarea'

const isRatingQuestion = (question: ReviewFormQuestion) =>
  question.type === 'rating_bar'

const isQuestionWithCareModal = (question: ReviewFormQuestion) =>
  question.identifier === 'review_main_rate'
</script>
