<template>
  <p v-if="stepId === 'details'" class="text-static-default-hi body-1">
    {{ i18n(translations.morePrecisions) }}
  </p>
</template>

<script lang="ts" setup>
import type { StepId } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from '../translations/review-form.translations'

defineProps<{ stepId: StepId | null }>()

const i18n = useI18n()
</script>
