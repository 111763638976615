import { type MaybeRef, type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import {
  type ValidateMethod,
  type Validator,
  makeValidate,
  min,
  required,
} from '@ds/components/Form'

import type { ReviewFormQuestion } from '../models/review-form'
import translations from '../translations/review-form.translations'

const MIN_RATING = 1

export function buildReviewFormValidators({
  questions,
  i18n,
}: {
  questions: MaybeRefOrGetter<ReviewFormQuestion[]>
  i18n: I18n
}): MaybeRef<ValidateMethod<Record<string, string | number>>> {
  return computed(() => {
    const validatorsObject: Record<string, Validator<object>[]> = {}

    toValue(questions).forEach((question) => {
      const validators: Validator<object>[] = []

      if (!question.required) return

      if (question.required)
        validators.push(required(i18n(translations.validationText)))

      if (question.type === 'rating_bar')
        validators.push(min(MIN_RATING, i18n(translations.validationText)))

      validatorsObject[question.identifier] = validators
    })

    return makeValidate(validatorsObject)
  })
}
