<template>
  <RevCard class="flex grow flex-col gap-24 p-24 md:h-fit md:w-fit">
    <div v-if="averageRate" class="flex items-center gap-12">
      <RevRating :score="averageRate" />
      <div class="text-static-default-low caption mt-1">
        {{ i18n(translations.globalRate) }}
      </div>
    </div>
    <RevDivider v-if="averageRate" />

    <ProductInfos :averageRate :productImage :productTitle />
  </RevCard>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevRating } from '@ds/components/Rating'

import ProductInfos from './ProductInfos.vue'
import translations from './ProductInfosCard.translations'

const i18n = useI18n()
defineProps<{
  averageRate?: number
  productTitle: string
  productImage: string
}>()
</script>
