export default {
  wrongAccountTitle: {
    id: 'review_form_error_403_title',
    defaultMessage: 'Oops. Wrong account.',
  },
  unauthorizedTitle: {
    id: 'review_form_error_401_title',
    defaultMessage: "Oops. You're not connected.",
  },
  genericTitle: {
    id: 'review_form_error_generic_title',
    defaultMessage: 'Uh oh. We broke something.',
  },
  wrongAccountMessage: {
    id: 'review_form_error_403_message_changed',
    defaultMessage:
      'You are currently logged in as {mail}. Please log out, then log back in with the correct details.',
  },
  unauthorizedMessage: {
    id: 'review_form_error_401_message',
    defaultMessage:
      'You are not logged in. If you have a token it is unused or incorrect.',
  },
  genericMessage: {
    id: 'review_form_error_generic_message',
    defaultMessage:
      'Oops! An error has occurred. Thank you for trying again later.',
  },
  signOut: {
    id: 'review_form_sign_out',
    defaultMessage: 'Sign Out',
  },
  signIn: {
    id: 'review_form_sign_in',
    defaultMessage: 'Sign In',
  },
} as const
