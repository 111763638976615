export default {
  titleBad: {
    id: 'help_card_title',
    defaultMessage: 'We can probably help.',
  },
  bodyBad: {
    id: 'help_card_text',
    defaultMessage:
      'Our Help Center is the best way to get in touch with us about your order, especially if something didn"t go as you expected.',
  },
  titleBadSolvedClaim: {
    id: 'card_incentive_customer_support_title_solved_claim',
    defaultMessage: 'Still having an issue?',
  },
  bodyBadSolvedClaim: {
    id: 'card_incentive_customer_support_subtitle_solved_claim',
    defaultMessage:
      'You can always reach out if your issue continues. Get in touch with our Customer Care Team by opening a service request, and we’ll get back to you within 1 business day.',
  },
  contactMerchant: {
    id: 'help_card_button',
    defaultMessage: 'Get help',
  },
  backToAccount: {
    id: 'review_form_feedback_button_good',
    defaultMessage: 'Back to your account',
  },
  titleGood: {
    id: 'review_form_feedback_title_good',
    defaultMessage: 'Thanks for the positive feedback!',
  },
  bodyGood: {
    id: 'review_form_feedback_body_good',
    defaultMessage: 'It really warms our little hearts.',
  },
  downloadApp: {
    id: 'download_app_button',
    defaultMessage: 'Download the app',
  },
  downloadAppQRC: {
    id: 'download_app_qrc',
    defaultMessage: 'Scan the code with your smartphone or visit {link}',
  },
  negativeTitle: {
    id: 'review_collection_summary_title_negative',
    defaultMessage: 'Thanks for telling it like it is.',
  },
  negativeText: {
    id: 'review_collection_summary_text_negative',
    defaultMessage:
      'We know it took time and effort to share your experience and it won’t get lost in the abyss. This is the kind of stuff that helps us improve.',
  },
  positiveTitle: {
    id: 'review_collection_summary_title_positive',
    defaultMessage: 'Let’s ride off into the sunset together.',
  },
  positiveText: {
    id: 'review_collection_summary_text_positive',
    defaultMessage:
      'We’ve never been in love, but this feels close. Thanks for taking the time to drop us a line. We lurve you.',
  },
  positiveTitleSolvedClaim: {
    id: 'review_collection_summary_title_solved_claim',
    defaultMessage: 'Thanks for the update',
  },
  positiveTextSolvedClaim: {
    id: 'review_collection_summary_text_solved_claim',
    defaultMessage: 'You’ll see this posted along with your original review.',
  },
} as const
