import { useRoute } from '#imports'
import { computed } from 'vue'

export function useReviewCollectionSource() {
  const route = useRoute()

  const source = computed(
    () => route.query.source?.toString() || 'review_collection',
  )
  const isSolvedClaim = computed(() => source.value === 'solved_claim')

  return {
    source,
    isSolvedClaim,
  }
}
