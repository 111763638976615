export default {
  signOut: {
    id: 'review_form_sign_out',
    defaultMessage: 'Sign Out',
  },
  signIn: {
    id: 'review_form_sign_in',
    defaultMessage: 'Sign In',
  },
} as const
