import { computed } from 'vue'

import { useRouterStep } from './useRouterStep'

export const useFormStep = () => {
  const { goToStep, step: formStep } = useRouterStep()

  const isFirstStep = computed(() => formStep.value === 0)

  const increment = () => goToStep(formStep.value + 1)
  const decrement = () => goToStep(formStep.value - 1)
  const goToFirstStep = () => goToStep(0)

  return {
    formStep,
    isFirstStep,
    increment,
    decrement,
    goToFirstStep,
  }
}
