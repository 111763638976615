export default {
  textAreaPlaceholder: {
    id: 'review_form_textarea_placeholder',
    defaultMessage: 'Quality of the product, customer service, delivery, etc.',
  },
  textAreaPlaceholderSolvedClaim: {
    id: 'review_form_textarea_placeholder_solved_claim',
    defaultMessage: 'Type your updated review here.',
  },
} as const
