<template>
  <button
    class="flex items-center gap-8"
    data-test="go-back-button"
    type="button"
    variant="secondary"
  >
    <IconArrowLeft aria-labelledby="backLabel" size="20" />
    <span class="text-static-default-hi body-2-link underline">{{
      i18n(translations.goBack)
    }}</span>
  </button>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import translations from './GoBackButton.translations'

const i18n = useI18n()
</script>
