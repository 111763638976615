import { type Ref, computed } from 'vue'

import type { HttpError } from '@backmarket/http-api'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import type { ReviewCollectionDisplayedError } from '../models/displayed-error'
import errorTranslations from '../translations/error.translations'

function mapToDisplayedError({
  error,
  i18n,
  userEmail,
}: {
  error: HttpError | null
  i18n: I18n
  userEmail: string | undefined
}): ReviewCollectionDisplayedError | null {
  if (!error) return null

  const { statusCode } = error

  switch (statusCode) {
    case 401:
      return {
        title: i18n(errorTranslations.unauthorizedTitle),
        message: i18n(errorTranslations.unauthorizedMessage),
        type: 'not-connected',
      }

    case 403:
      return {
        title: i18n(errorTranslations.wrongAccountTitle),
        message: i18n(errorTranslations.wrongAccountMessage, {
          mail: userEmail,
        }),
        type: 'wrong-account',
      }

    default:
      return {
        title: i18n(errorTranslations.genericTitle),
        message: i18n(errorTranslations.genericMessage),
        type: 'other',
      }
  }
}

export function useDisplayedError() {
  const i18n = useI18n()
  const userStore = useUserStore()
  const logger = useLogger()

  function buildDisplayedError(error: Ref<HttpError | null>) {
    if (error.value && ![403, 401].includes(error.value.statusCode)) {
      logger.error('[ReviewForm] GET', {
        error: error.value,
        owners: ['bot-squad-content-front'],
      })
    }

    return computed(() =>
      mapToDisplayedError({
        error: error.value,
        i18n,
        userEmail: userStore?.user?.email,
      }),
    )
  }

  return { buildDisplayedError }
}
