import { createHttpEndpoint } from '../utils'

import type {
  ReviewCollectionGetResponse,
  ReviewCollectionPostBody,
} from './types/review-form'

/**
 * Endpoint to get the review collection configuration needed to leave a review
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#/review-collection/get-sales-order-item-review-collection}
 */
export const getOrderReviewForm =
  createHttpEndpoint<ReviewCollectionGetResponse>({
    method: 'GET',
    operationId: 'fetchReviewFormByOrder',
    path: '/reviews/sales-order-item/:uuid/review-collection',
  })

export type ReviewCollectionPostResponse = {
  averageRate: number
}

/**
 * @see {@link https://devportal.backmarket.io/catalog/default/api/review-service-api/definition#/review-collection/post-v2-sales-order-item-sales_order_item_id-review-collection}
 */
export const postOrderReviewForm = createHttpEndpoint<
  ReviewCollectionPostResponse,
  ReviewCollectionPostBody
>({
  method: 'POST',
  operationId: 'postReviewFormByOrder',
  path: '/reviews/v3/sales-order-item/:uuid/review',
})
