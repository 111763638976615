export default {
  bad: {
    id: 'review_form_tooltip_bad',
    defaultMessage: 'Disappointing',
  },
  error: {
    id: 'review_form_validation_text',
    defaultMessage: 'No response, no opinion.',
  },
  excellent: {
    id: 'review_form_tooltip_excellent',
    defaultMessage: 'Awesome',
  },
  good: {
    id: 'review_form_tooltip_good',
    defaultMessage: 'Pretty Good',
  },
  naught: {
    id: 'review_form_tooltip_naught',
    defaultMessage: 'Bad',
  },
  neutral: {
    id: 'review_form_tooltip_neutral',
    defaultMessage: 'Meh',
  },
} as const
