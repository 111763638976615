import { useRoute, useRouter } from '#imports'
import { type Ref, computed } from 'vue'

export type UseRouterStep = () => {
  step: Ref<number>
  goToStep: (step: number) => void
}
export const useRouterStep: UseRouterStep = () => {
  const router = useRouter()
  const route = useRoute()

  const step = computed(() => {
    const routeStep = route.query.step?.toString() || ''

    return Math.max(Number.parseInt(routeStep, 10) || 0, 0)
  })
  const goToStep = (newStep: number) =>
    router.push({
      query: {
        ...route.query,
        step: newStep,
      },
    })

  return { step, goToStep }
}
