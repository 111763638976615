<template>
  <div class="flex flex-col">
    <QuestionHeading class="mb-16" :subtitle :title />

    <RevInputTextarea
      :id
      v-model="comment"
      :class="{
        '!border-action-danger-low': !!errorMessage,
      }"
      data-test="comment-input"
      :label
      :rows="5"
    />

    <div v-if="!!errorMessage" class="text-static-danger-hi mt-8">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputTextarea } from '@ds/components/InputTextarea'

import { useReviewCollectionSource } from '../../composables/useReviewCollectionSource'
import QuestionHeading from '../QuestionHeading.vue'

import translations from './CommentInput.translations'

defineProps<{
  id: string
  title: string
  subtitle: string
  errorMessage?: string
}>()

const i18n = useI18n()
const { isSolvedClaim } = useReviewCollectionSource()

const comment = defineModel<string>()
const label = computed(() =>
  isSolvedClaim.value
    ? i18n(translations.textAreaPlaceholderSolvedClaim)
    : i18n(translations.textAreaPlaceholder),
)
</script>
