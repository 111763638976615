import { useRoute } from '#imports'
import { type Ref, computed, ref, watch } from 'vue'

import { getOrderReviewForm } from '@backmarket/http-api/src/api-specs-reviews/order-review-form'
import type { ReviewCollectionGetResponse } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { objectKeys } from '@backmarket/utils/object/objectKeys'

import type { ReviewForm } from '../models/review-form'

import { useBuildForm } from './useBuildForm'
import { useFormStep } from './useFormStep'
import { usePostOrderReviewFormMutation } from './usePostOrderReviewFormMutation'
import { useReviewCollectionSource } from './useReviewCollectionSource'

function shouldSkipSendingRequest({
  newValues,
  savedValues,
  formStep,
  lastSubmittedStep,
}: {
  newValues: ReviewForm
  savedValues: ReviewForm | undefined
  formStep: number
  lastSubmittedStep: number
}) {
  if (!savedValues) return false

  const identicalValues = objectKeys(newValues).every((key) => {
    const newValue = newValues[key]
    const savedValue = savedValues[key]

    return newValue === savedValue
  })

  const hasAlreadySubmittedThisStep = formStep <= lastSubmittedStep

  const skipRequest = identicalValues && hasAlreadySubmittedThisStep

  return skipRequest
}

function useLiveAverageRate({
  reviewCollectionData,
  postOrderAverageRate,
  isSolvedClaim,
}: {
  reviewCollectionData: Ref<ReviewCollectionGetResponse | null>
  postOrderAverageRate: Ref<number | undefined>
  isSolvedClaim: Ref<boolean>
}) {
  const averageRate = ref(
    isSolvedClaim.value ? undefined : reviewCollectionData.value?.averageRate,
  )

  watch(postOrderAverageRate, (newAverageRate) => {
    averageRate.value = newAverageRate
  })

  return { averageRate }
}

function removeUploadPicturesStep(
  apiData: ReviewCollectionGetResponse,
): ReviewCollectionGetResponse {
  const { steps } = apiData

  const stepsWithoutAttachments = steps.filter(
    (step) => step.identifier !== 'attachments',
  )

  return {
    ...apiData,
    steps: stepsWithoutAttachments,
  }
}
export async function useReviewCollectionForm() {
  const i18n = useI18n()

  const { params, query } = useRoute()
  const { isSolvedClaim } = useReviewCollectionSource()

  const {
    increment: incrementStep,
    formStep,
    decrement: decrementStep,
    isFirstStep,
    goToFirstStep,
  } = useFormStep()

  const maxStepSubmitted = ref(-1)

  const {
    pending: submissionPending,
    averageRate: postOrderAverageRate,
    isPositiveReview,
    submit,
  } = usePostOrderReviewFormMutation()

  /**
   * NB: Since we are awaiting here, any function calls after this will not have access to the Nuxt instance
   * Calling a composable after this will throw an error
   */
  const { error, data } = await useHttpFetch(getOrderReviewForm, {
    pathParams: { uuid: params.orderlineId },
    queryParams: {
      ...insertIf(!!query?.authToken, { authToken: query.authToken }),
      ...insertIf(!!query?.source, { source: query.source }),
    },
    transform: removeUploadPicturesStep,
  })

  const { averageRate } = useLiveAverageRate({
    reviewCollectionData: data,
    postOrderAverageRate,
    isSolvedClaim,
  })

  const { form, questions, saveValuesInForm, validators, isSummaryStep } =
    useBuildForm(data, formStep, i18n, isSolvedClaim)

  const goToPreviousStep = (values: ReviewForm) => {
    saveValuesInForm(values)
    decrementStep()
  }

  const maxFormStep = computed(() =>
    data.value ? data.value.steps.length - 1 : 0,
  )

  async function submitStep(newValues: ReviewForm) {
    if (
      shouldSkipSendingRequest({
        savedValues: form.value,
        newValues,
        formStep: formStep.value,
        lastSubmittedStep: maxStepSubmitted.value,
      })
    ) {
      incrementStep()

      return
    }

    saveValuesInForm(newValues)

    await submit({
      stepValues: newValues,
      questions: questions.value,
      orderlineId: params.orderlineId,
      authToken: query.authToken as string,
    })

    maxStepSubmitted.value = Math.max(formStep.value, maxStepSubmitted.value)

    incrementStep()
  }

  return {
    error,
    submissionPending,
    questions,
    submitStep,
    goToPreviousStep,
    goToFirstStep,
    isSummaryStep,
    formStep,
    isFirstStep,
    validators,
    form,
    averageRate,
    isPositiveReview,
    data,
    maxFormStep,
  }
}
