export default {
  reviewMainRateTitle: {
    id: 'review_main_title',
    defaultMessage: 'How was your experience on Back Market',
  },
  reviewMainRateSubtitle: {
    id: 'review_main_subtitle',
    defaultMessage: 'We want to hear from you so we can improve.',
  },
  reviewMainRateTitleSolvedClaim: {
    id: 'review_main_title_solved_claim',
    defaultMessage: 'How did it go this time?',
  },
  reviewMainRateSubtitleSolvedClaim: {
    id: 'review_main_subtitle_solved_claim',
    defaultMessage: 'We’d love to know how your experience changed.',
  },
  reviewMainCommentTitle: {
    id: 'review_form_tellus_more',
    defaultMessage: 'Write a review',
  },
  reviewMainCommentSubtitle: {
    id: 'review_form_comment_subtitle_0',
    defaultMessage:
      'Tell us and other customers how you really feel (without entering any personal info).',
  },
  reviewMainCommentTitleSolvedClaim: {
    id: 'review_main_comment_title_solved_claim',
    defaultMessage: 'Write another review',
  },
  reviewMainCommentSubtitleSolvedClaim: {
    id: 'review_main_comment_subtitle_solved_claim',
    defaultMessage: 'This will be published along with your previous review.',
  },
  reviewContinue: {
    id: 'review_form_continue',
    defaultMessage: 'Continue to post review',
  },
  accessoriesTitle: {
    id: 'review_accessories_title',
    defaultMessage: 'Accessories',
  },
  accessoriesSubtitle: {
    id: 'review_accessories_subtitle',
    defaultMessage:
      'Did the product come with accessories and are they in good shape?',
  },
  aestheticAppearanceTitle: {
    id: 'review_aesthetic_appearance_title',
    defaultMessage: 'Appearance',
  },
  aestheticAppearanceSubtitle: {
    id: 'review_aesthetic_appearance_subtitle',
    defaultMessage:
      'Did the appearance of the product you received match the grade selected?',
  },
  batteryTitle: {
    id: 'review_battery_title',
    defaultMessage: 'Battery',
  },
  batterySubtitle: {
    id: 'review_battery_subtitle',
    defaultMessage: 'Is battery life sufficient for normal use?',
  },
  cameraTitle: {
    id: 'review_camera_title',
    defaultMessage: 'Lens & Camera',
  },
  cameraSubtitle: {
    id: 'review_camera_subtitle',
    defaultMessage: 'Are the lens and camera working',
  },
  overallPerformanceTitle: {
    id: 'review_overall_performance_title',
    defaultMessage: 'Overall performance',
  },
  overallPerformanceSubtitle: {
    id: 'review_overall_performance_subtitle',
    defaultMessage: 'Does the product function as expected?',
  },
  packagingCleanlinessTitle: {
    id: 'review_packaging_cleanliness_title',
    defaultMessage: 'Packaging & cleanliness',
  },
  packagingCleanlinessSubtitle: {
    id: 'review_packaging_cleanliness_subtitle',
    defaultMessage: 'Was the product clean and intact in the packaging?',
  },
  shippingTitle: {
    id: 'review_shipping_title',
    defaultMessage: 'Shipping',
  },
  shippingSubtitle: {
    id: 'review_shipping_subtitle',
    defaultMessage: 'Was the delivery time provided accurate?',
  },
  morePrecisions: {
    id: 'review_form_more_precisions',
    defaultMessage: 'Add a little more detail to your review!',
  },
  validate: {
    id: 'validate',
    defaultMessage: 'Validate',
  },
  stopLine: {
    id: 'review_form_product_step_stopline',
    defaultMessage: 'It feels good when the questions stop.',
  },
  legalText: {
    id: 'review_form_legal_text',
    defaultMessage:
      'By submitting, you agree to our {privacy, html} and acknowledge that your first name and review will be published. For more info, see our {otherTerms, html}',
  },
  privacyLink: {
    id: 'review_form_privacy_link',
    defaultMessage: 'privacy policy',
  },
  otherTermsLink: {
    id: 'review_form_other_terms_link',
    defaultMessage: 'Other legal information notice',
  },
  validationText: {
    id: 'review_form_validation_text',
    defaultMessage: 'No response, no opinion.',
  },
} as const
