import { type Ref, computed } from 'vue'

import type { I18n } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { openModal } from '@ds/components/ModalBase'

import {
  CARE_MODAL_NEGATIVE_REVIEW_MAX_RATE,
  REVIEW_FORM_CARE_MODAL,
} from '../components/CareModal/CareModal.constants'
import type { ReviewForm } from '../models/review-form'
import translations from '../translations/review-form.translations'

import { useDisplayedError } from './useDisplayedError'
import { useReviewCollectionForm } from './useReviewCollectionForm'
import { useReviewCollectionTracking } from './useReviewCollectionTracking'

function useSubmitButtonLabel({
  formStep,
  maxFormStep,
  i18n,
}: {
  formStep: Ref<number>
  maxFormStep: Ref<number>
  i18n: I18n
}) {
  return computed(() => {
    return formStep.value === maxFormStep.value
      ? i18n(translations.validate)
      : i18n(translations.reviewContinue)
  })
}
export async function useReviewCollection() {
  const { buildDisplayedError } = useDisplayedError()

  const {
    trackRateChange: _trackRateChange,
    trackStepSubmission,
    trackEditReview,
  } = useReviewCollectionTracking()

  const i18n = useI18n()
  const {
    data,
    error,
    formStep,
    isSummaryStep,
    averageRate,
    submitStep,
    maxFormStep,
    goToFirstStep,
    isPositiveReview,
    ...reviewCollectionForm
  } = await useReviewCollectionForm()

  const displayedError = buildDisplayedError(error)

  const formStepId = computed(() =>
    data.value ? data.value.steps[formStep.value]?.identifier : null,
  )

  const product = computed(() => data.value?.product)

  const showSummary = computed(() => isSummaryStep())

  const submitButtonLabel = useSubmitButtonLabel({
    formStep,
    i18n,
    maxFormStep,
  })

  async function submitStepWithTracking(values: ReviewForm) {
    /*
     * we have to store the values of the current step / questions before submitting it because they will be updated after the submission.
     * And we can't call trackStepSubmission before because we need the latest averageRate from the POST request
     * */
    const currentStep = formStep.value
    const stepQuestions = reviewCollectionForm.questions.value
    await submitStep(values)

    trackStepSubmission({
      values,
      step: currentStep,
      questions: stepQuestions,
      product: data.value?.product,
      averageRate: averageRate.value,
    })
  }

  function editReview() {
    trackEditReview({
      isPositiveReview: isPositiveReview.value,
    })
    goToFirstStep()
  }

  function onRate({
    rate,
    withCareModal = false,
  }: {
    rate: number
    withCareModal?: boolean
  }) {
    _trackRateChange({ rate, step: formStep.value })

    if (withCareModal && rate <= CARE_MODAL_NEGATIVE_REVIEW_MAX_RATE) {
      openModal(REVIEW_FORM_CARE_MODAL)
    }
  }

  return {
    ...reviewCollectionForm,
    product,
    showSummary,
    displayedError,
    averageRate,
    formStepId,
    isPositiveReview,
    submitStep: submitStepWithTracking,
    onRate,
    submitButtonLabel,
    editReview,
  }
}
