<template>
  <p v-if="stepId === 'details'" class="text-static-default-hi body-1">
    {{ i18n(translations.stopLine) }}
  </p>

  <FormattedMessage
    v-if="stepId === 'main'"
    :definition="translations.legalText"
    tag="p"
  >
    <template #privacy>
      <RevLink target="_blank" :to="privacyLink">
        {{ i18n(translations.privacyLink) }}
      </RevLink>
    </template>

    <template #otherTerms>
      <RevLink target="_blank" :to="otherTermsLink">
        {{ i18n(translations.otherTermsLink) }}
      </RevLink>
    </template>
  </FormattedMessage>
</template>

<script lang="ts" setup>
import type { StepId } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import type { Location } from '@ds/types/Location'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import translations from '../translations/review-form.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()
const privacyLink: Location = resolveLocalizedRoute({
  name: CMS.LEGAL_PAGE,
  params: {
    pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
  },
})

const otherTermsLink: Location = resolveLocalizedRoute({
  name: CMS.LEGAL_PAGE,
  params: {
    pageName: LEGAL_PAGE_NAME.OTHER,
  },
})

defineProps<{ stepId: StepId | null }>()

const i18n = useI18n()
</script>
