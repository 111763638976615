import { type Ref, ref, watch } from 'vue'

import type {
  ReviewCollectionGetResponse,
  ReviewCollectionGetResponseQuestion,
} from '@backmarket/http-api/src/api-specs-reviews/types/review-form'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

import { buildReviewForm } from '../helpers/buildReviewForm'
import { buildReviewFormValidators } from '../helpers/buildValidators'
import type { ReviewForm, ReviewFormQuestion } from '../models/review-form'
import reviewFormTranslations from '../translations/review-form.translations'

function getQuestionText({
  question,
  i18n,
  isSolvedClaim,
}: {
  question: ReviewCollectionGetResponseQuestion
  i18n: I18n
  isSolvedClaim: boolean
}) {
  switch (question.identifier) {
    case 'review_main_rate':
      return isSolvedClaim
        ? {
            title: i18n(reviewFormTranslations.reviewMainRateTitleSolvedClaim),
            subtitle: i18n(
              reviewFormTranslations.reviewMainRateSubtitleSolvedClaim,
            ),
          }
        : {
            title: i18n(reviewFormTranslations.reviewMainRateTitle),
            subtitle: i18n(reviewFormTranslations.reviewMainRateSubtitle),
          }

    case 'review_main_comment':
      return isSolvedClaim
        ? {
            title: i18n(
              reviewFormTranslations.reviewMainCommentTitleSolvedClaim,
            ),
            subtitle: i18n(
              reviewFormTranslations.reviewMainCommentSubtitleSolvedClaim,
            ),
          }
        : {
            title: i18n(reviewFormTranslations.reviewMainCommentTitle),
            subtitle: i18n(reviewFormTranslations.reviewMainCommentSubtitle),
          }

    case 'review_overall_performance':
      return {
        title: i18n(reviewFormTranslations.overallPerformanceTitle),
        subtitle: i18n(reviewFormTranslations.overallPerformanceSubtitle),
      }

    case 'review_aesthetic_appearance':
      return {
        title: i18n(reviewFormTranslations.aestheticAppearanceTitle),
        subtitle: i18n(reviewFormTranslations.aestheticAppearanceSubtitle),
      }

    case 'review_packaging_cleanliness':
      return {
        title: i18n(reviewFormTranslations.packagingCleanlinessTitle),
        subtitle: i18n(reviewFormTranslations.packagingCleanlinessSubtitle),
      }

    case 'review_shipping':
      return {
        title: i18n(reviewFormTranslations.shippingTitle),
        subtitle: i18n(reviewFormTranslations.shippingSubtitle),
      }

    case 'review_battery':
      return {
        title: i18n(reviewFormTranslations.batteryTitle),
        subtitle: i18n(reviewFormTranslations.batterySubtitle),
      }

    case 'review_camera':
      return {
        title: i18n(reviewFormTranslations.cameraTitle),
        subtitle: i18n(reviewFormTranslations.cameraSubtitle),
      }

    case 'review_accessories':
      return {
        title: i18n(reviewFormTranslations.accessoriesTitle),
        subtitle: i18n(reviewFormTranslations.accessoriesSubtitle),
      }

    default:
      return {
        title: '',
        subtitle: '',
      }
  }
}

function addTitleSubtitleToQuestions({
  questions,
  i18n,
  isSolvedClaim,
}: {
  questions: ReviewCollectionGetResponseQuestion[]
  i18n: I18n
  isSolvedClaim: boolean
}): ReviewFormQuestion[] {
  return questions.map((question) => {
    const { subtitle, title } = getQuestionText({
      question,
      i18n,
      isSolvedClaim,
    })

    return {
      identifier: question.identifier,
      type: question.type,
      title,
      subtitle,
      required: question.required,
    } satisfies ReviewFormQuestion
  })
}

function buildQuestionsForCurrentStep({
  stepIndex,
  apiData,
  i18n,
  isSolvedClaim,
}: {
  stepIndex: number
  apiData: ReviewCollectionGetResponse
  i18n: I18n
  isSolvedClaim: boolean
}) {
  const currentStep = apiData.steps[stepIndex]

  const { questions: apiQuestions } = currentStep

  return addTitleSubtitleToQuestions({
    questions: apiQuestions,
    i18n,
    isSolvedClaim,
  })
}

/**
 * Builds the form, validators and questions for the current step
 * based on the API data and current step index
 */
export function useBuildForm(
  data: Ref<ReviewCollectionGetResponse | null>,
  formStep: Ref<number>,
  i18n: I18n,
  isSolvedClaim: Ref<boolean>,
) {
  const form = ref<ReviewForm>()
  const questions = ref<ReviewFormQuestion[]>([])

  const isSummaryStep = () =>
    data.value ? formStep.value >= data.value.steps.length : false

  /**
   * This is necessary to maintain state when going back to a previous step
   */
  function saveValuesInForm(values: Record<string, string | number>) {
    form.value = {
      ...form.value,
      ...values,
    }
  }

  watch(
    [data, formStep],
    () => {
      if (!data.value) return

      if (isSummaryStep()) return

      questions.value = buildQuestionsForCurrentStep({
        apiData: data.value,
        stepIndex: formStep.value,
        i18n,
        isSolvedClaim: isSolvedClaim.value,
      })
      // NB: form cannot be a computed as the value of its fields will be updated by the input components
      form.value = buildReviewForm(
        questions.value,
        form.value,
        isSolvedClaim.value ? [] : data.value.answers,
      )
    },
    { immediate: true },
  )

  const validators = buildReviewFormValidators({ questions, i18n })

  return {
    form,
    questions,
    validators,
    saveValuesInForm,
    isSummaryStep,
  }
}
