import type { ReviewCollectionGetResponseAnswer } from '@backmarket/http-api/src/api-specs-reviews/types/review-form'

import type { ReviewForm, ReviewFormQuestion } from '../models/review-form'

const getQuestionDefaultValue = (question: ReviewFormQuestion) => {
  if (question.type === 'rating_bar') return 0
  if (question.type === 'textarea') return ''

  return ''
}

const getQuestionValueFromApi = (
  question: ReviewFormQuestion,
  answers: ReviewCollectionGetResponseAnswer[] = [],
) => {
  const answer = answers.find((ans) => ans.identifier === question.identifier)

  if (!answer) return getQuestionDefaultValue(question)

  if ('rate' in answer.value) return answer.value.rate

  if ('comment' in answer.value) return answer.value.comment

  return ''
}

export function buildReviewForm(
  questions: ReviewFormQuestion[],
  form: ReviewForm | undefined,
  apiAnswers: ReviewCollectionGetResponseAnswer[],
) {
  // this is necessary to maintain state when going back to a previous step
  const previousStepValues = form || {}

  const reviewForm: ReviewForm<ReviewFormQuestion[]> = {}

  questions.forEach((question) => {
    const formValue = form ? form[question.identifier] : null
    const apiValue = getQuestionValueFromApi(question, apiAnswers)
    reviewForm[question.identifier] = formValue || apiValue
  })

  return {
    ...previousStepValues,
    ...reviewForm,
  }
}
