export default {
  careModalTitle: {
    id: 'review_form_care_modal_title',
    defaultMessage: 'We can probably help',
  },
  careModalText: {
    id: 'review_form_care_modal_text',
    defaultMessage:
      'Our Help Center is the best way to get in touch with us about your order, especially if something didn’t go as you expected.',
  },
  careModalTitleSolvedClaim: {
    id: 'review_collection_help_center_modal_title_solved_claim',
    defaultMessage: 'Still having an issue?',
  },
  careModalTextSolvedClaim: {
    id: 'review_collection_help_center_modal_text_solved_claim',
    defaultMessage:
      'You can always reach out if your issue continues. Get in touch with our Customer Care Team by opening a service request, and we’ll get back to you within 1 business day.',
  },
  careModalButton: {
    id: 'review_form_care_modal_button',
    defaultMessage: 'Go to help center',
  },
  careModalClose: {
    id: 'review_form_modal_close',
    defaultMessage: 'Close',
  },
} as const
